<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content cols-2">
  <div class="flex-space-between-layout template-header">
    <h1>Compliance Handover</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner
      *ngIf="isLoading"
      sidepaneLoading="true"
    ></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-heading">Circumstances and Objectives</div>
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column">
            <div
              [hfFormField]="control('currentMonthlyBudget')"
              class="ev-form-field"
            >
              <label>Current Monthly Budget</label>
              <hfc-text-input
                prefix="£"
                [disableDecimal]="true"
                currency="true"
                formControlName="currentMonthlyBudget"
              ></hfc-text-input>
            </div>

            <div
              [hfFormField]="control('clientPrioritydetails')"
              class="ev-form-field"
            >
              <label>Detail client priorities / thoughts / feelings</label>
              <hfc-textarea-input
                [rows]="4"
                [autoexpand]="true"
                formControlName="clientPrioritydetails"
              ></hfc-textarea-input>
            </div>
          </div>

          <div class="ev-sidepane-form-column">
            <div class="ev-form-field" style="margin-top: 24px">
              <hf-checkbox
                label="Existing Provisions"
                formControlName="hasExistingProvisions"
              ></hf-checkbox>
            </div>

            <div *ngIf="enabled('covers')" class="ev-form-field">
              <div
                *ngIf="isSubmitted && control('covers').invalid"
                class="ev-form-error"
              >
                Please specify existing provisions
              </div>

              <hfc-info-box-container class="info-box-container-full-width">
                <hfc-add-button (click)="onAddCoverClicked()"
                  >Add Cover</hfc-add-button
                >

                <hfc-info-box
                  *ngFor="let cover of value.covers; let i = index"
                  (click)="onEditCoverClicked(cover)"
                >
                  <ng-template #detailsTemplate>
                    Cover #{{ i + 1 }}
                    <ng-container *ngIf="cover.provider">
                      ({{ cover.provider }})
                    </ng-container>
                  </ng-template>
                </hfc-info-box>
              </hfc-info-box-container>
            </div>

            <div *ngIf="enabled('shortfall')" class="ev-form-field">
              <hf-checkbox
                label="Shortfalls Identified"
                formControlName="shortfall"
              ></hf-checkbox>
            </div>

            <div
              *ngIf="enabled('shortfallDetails')"
              [hfFormField]="control('shortfallDetails')"
              class="ev-form-field"
            >
              <label>Please detail shortfalls identified</label>
              <hfc-textarea-input
                [rows]="4"
                [autoexpand]="true"
                formControlName="shortfallDetails"
              ></hfc-textarea-input>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="enabled('_MORTGAGE')"
        [hfFormField]="control('_MORTGAGE')"
        class="ev-sidepane-form-section top-border"
      >
        <div class="ev-sidepane-form-heading">Mortgage Protection</div>
        <div
          *ngIf="isSubmitted && control('_MORTGAGE').invalid"
          class="ev-form-error"
        >
          Please add all benefit details before submitting.
        </div>
        <div class="ev-sidepane-form-content">
          <div
            *ngIf="enabled('clientRefusedMortgageProtection')"
            class="ev-sidepane-form-column-full-width"
          >
            <hf-banner iconName="info">
              No mortgage protection sourced
            </hf-banner>
          </div>

          <!-- <div *ngIf="enabled('clientRefusedMortgageProtection')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedMortgageProtection')"> -->
          <!--     <hf-checkbox label="Client Refused" formControlName="clientRefusedMortgageProtection"></hf-checkbox> -->
          <!--   </div> -->
          <!-- </div> -->
          <!-- <div *ngIf="enabled('clientRefusedMortgageProtectionReason')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedMortgageProtectionReason')"> -->
          <!--     <label>Reason for Refusal</label> -->
          <!--     <hfc-textarea-input [rows]="4" [autoexpand]="true" formControlName="clientRefusedMortgageProtectionReason"></hfc-textarea-input> -->
          <!--   </div> -->
          <!-- </div> -->

          <div
            *ngIf="benefits['MORTGAGE'].length"
            class="ev-sidepane-form-column-full-width"
          >
            <div class="ev-form-field info-box-container">
              <hfc-info-box-container>
                <hfc-info-box
                  *ngFor="let benefit of benefits['MORTGAGE']"
                  [title]="BenefitTypeName.get(benefit.type)"
                  [details]="koala"
                  (click)="onBenefitClicked(benefit)"
                >
                  <ng-template #detailsTemplate>
                    <div class="benefit">
                      <div>
                        {{ benefit.provider.name }}
                        <span class="bullet">•</span>
                        {{ getBenefitConverFor(benefit) }}
                      </div>
                      <div class="benefit-state" [ngClass]="benefit.state">
                        {{ BenefitHandoverStateName.get(benefit.state) }}
                      </div>
                    </div>
                  </ng-template>
                </hfc-info-box>
              </hfc-info-box-container>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="enabled('_PERSONAL_FAMILY')"
        [hfFormField]="control('_PERSONAL_FAMILY')"
        class="ev-sidepane-form-section top-border"
      >
        <div class="ev-sidepane-form-heading">Personal / Family Protection</div>
        <div
          *ngIf="isSubmitted && control('_PERSONAL_FAMILY').invalid"
          class="ev-form-error"
        >
          Please add all benefit details before submitting.
        </div>
        <div class="ev-sidepane-form-content">
          <div
            *ngIf="enabled('clientRefusedPersonalProtection')"
            class="ev-sidepane-form-column-full-width"
          >
            <hf-banner iconName="info">
              No personal/family protection sourced
            </hf-banner>
          </div>

          <!-- <div *ngIf="enabled('clientRefusedPersonalProtection')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedPersonalProtection')"> -->
          <!--     <hf-checkbox label="Client Refused" formControlName="clientRefusedPersonalProtection"></hf-checkbox> -->
          <!--   </div> -->
          <!-- </div> -->
          <!-- <div *ngIf="enabled('clientRefusedPersonalProtectionReason')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedPersonalProtectionReason')"> -->
          <!--     <label>Reason for Refusal</label> -->
          <!--     <hfc-textarea-input [rows]="4" [autoexpand]="true" formControlName="clientRefusedPersonalProtectionReason"></hfc-textarea-input> -->
          <!--   </div> -->
          <!-- </div> -->

          <div
            *ngIf="benefits['PERSONAL_FAMILY'].length"
            class="ev-sidepane-form-column-full-width"
          >
            <div class="ev-form-field info-box-container">
              <hfc-info-box-container>
                <hfc-info-box
                  *ngFor="let benefit of benefits['PERSONAL_FAMILY']"
                  [title]="BenefitTypeName.get(benefit.type)"
                  [details]="koala"
                  (click)="onBenefitClicked(benefit)"
                >
                  <ng-template #detailsTemplate>
                    <div class="benefit">
                      <div>
                        {{ benefit.provider.name }}
                        <span class="bullet">•</span>
                        {{ getBenefitConverFor(benefit) }}
                      </div>
                      <div class="benefit-state" [ngClass]="benefit.state">
                        {{ BenefitHandoverStateName.get(benefit.state) }}
                      </div>
                    </div>
                  </ng-template>
                </hfc-info-box>
              </hfc-info-box-container>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="enabled('_INCOME_PROTECTION')"
        [hfFormField]="control('_INCOME_PROTECTION')"
        class="ev-sidepane-form-section top-border"
      >
        <div class="ev-sidepane-form-heading">Income Protection</div>
        <div
          *ngIf="isSubmitted && control('_INCOME_PROTECTION').invalid"
          class="ev-form-error"
        >
          Please add all benefit details before submitting.
        </div>
        <div class="ev-sidepane-form-content">
          <div
            *ngIf="enabled('clientRefusedIncomeProtection')"
            class="ev-sidepane-form-column-full-width"
          >
            <hf-banner iconName="info">
              No income protection sourced
            </hf-banner>
          </div>

          <!-- <div *ngIf="enabled('clientRefusedIncomeProtection')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedIncomeProtection')"> -->
          <!--     <hf-checkbox label="Client Refused" formControlName="clientRefusedIncomeProtection"></hf-checkbox> -->
          <!--   </div> -->
          <!-- </div> -->
          <!-- <div *ngIf="enabled('clientRefusedIncomeProtectionReason')" class="ev-sidepane-form-column"> -->
          <!--   <div class="ev-form-field" [hfFormField]="control('clientRefusedIncomeProtectionReason')"> -->
          <!--     <label>Reason for Refusal</label> -->
          <!--     <hfc-textarea-input [rows]="4" [autoexpand]="true" formControlName="clientRefusedIncomeProtectionReason"></hfc-textarea-input> -->
          <!--   </div> -->
          <!-- </div> -->

          <div
            *ngIf="benefits['INCOME_PROTECTION'].length"
            class="ev-sidepane-form-column-full-width"
          >
            <div class="ev-form-field info-box-container">
              <hfc-info-box-container>
                <hfc-info-box
                  *ngFor="let benefit of benefits['INCOME_PROTECTION']"
                  [title]="BenefitTypeName.get(benefit.type)"
                  [details]="koala"
                  (click)="onBenefitClicked(benefit)"
                >
                  <ng-template #detailsTemplate>
                    <div class="benefit">
                      <div>
                        {{ benefit.provider.name }}
                        <span class="bullet">•</span>
                        {{ getBenefitConverFor(benefit) }}
                      </div>
                      <div class="benefit-state" [ngClass]="benefit.state">
                        {{ BenefitHandoverStateName.get(benefit.state) }}
                      </div>
                    </div>
                  </ng-template>
                </hfc-info-box>
              </hfc-info-box-container>
            </div>
          </div>
        </div>
      </div>

      <hf-unmet-needs
        *ngIf="enabled('unmetNeeds') && dto.unmetNeeds?.length"
        [form]="form"
        [isSubmitted]="isSubmitted"
      ></hf-unmet-needs>

      <div class="ev-sidepane-form-section top-border">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column">
            <div [hfFormField]="control('notes')" class="ev-form-field">
              <label>Other Notes</label>
              <hfc-textarea-input
                [rows]="4"
                [autoexpand]="true"
                formControlName="notes"
              ></hfc-textarea-input>
            </div>
          </div>

          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <hf-checkbox
                label="Send Documentation to All Applicants"
                formControlName="sendDocumentationToApplicants"
              ></hf-checkbox>
            </div>
          </div>
          <div class="ev-sidepane-form-column">
            <div
              *ngIf="enabled('messageToApplicants')"
              [hfFormField]="control('messageToApplicants')"
              class="ev-form-field"
            >
              <label>Message to All Applicants (Optional)</label>
              <hfc-textarea-input
                [rows]="4"
                [autoexpand]="true"
                formControlName="messageToApplicants"
              ></hfc-textarea-input>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onSubmit()"
      >Hand Over</hfc-button
    >
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
