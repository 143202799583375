export function convertSecondsToTime(seconds: number): string {
  if (isNaN(seconds) || seconds < 0) {
    return "Invalid time"; // Handle invalid input
  }

  const hours = Math.floor(seconds / 3600);
  const remainingAfterHours = seconds % 3600;
  const minutes = Math.floor(remainingAfterHours / 60);
  const remainingSeconds = remainingAfterHours % 60;

  const hoursPart = hours > 0 ? `${hours}h` : "";
  const minutesPart = minutes > 0 ? `${minutes}m` : "";
  const secondsPart = remainingSeconds > 0 ? `${remainingSeconds}s` : "";

  return `${hoursPart} ${minutesPart} ${secondsPart}`.trim();
}
