import { Component, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Actions, ActionType, ofActionSuccessful } from "@ngxs/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fadeAnimation } from "../../../../../projects/client/src/app/shared/animations/fade.animation";
import { JournalEntry } from "../../models/journal-details";
import { MortgageTableParams } from "../../models/mortgage-dashboard";
import { Mortgage } from "../../modules/mortgages/state/mortgage.actions";
import { RemortgageDashboardService } from "../../modules/remortgage/pages/remortgage-dashboard/remortgage-dashboard.service";
import { Remortgage } from "../../modules/remortgage/state/remortgage.actions";
import {
  DataSourceFactory,
  TableParams,
  TableSettingsService,
} from "../table-utils/table-settings.service";
import { TableSorting } from "../table-utils/table-sorting.model";
import { GENERAL_DOCUMENT_TYPE, USER_TYPE } from "../../utils/variables.data";
import { JournalCategory } from "../../utils/journal.enum";

@Component({
  selector: "hf-journal",
  templateUrl: "./journal.component.html",
  styleUrls: ["./journal.component.scss"],
  animations: [fadeAnimation],
  // providers: [TableSettingsService],
})
export class JournalComponent implements OnInit, OnDestroy {
  @Input()
  public dataSourceFactory: DataSourceFactory<TableParams>;
  @Input() public refreshEntries: boolean = false;
  public callDataId?: number[];

  public items: JournalEntry[] = [];
  public initialized = false;
  public get remortgageId() {
    return this.route.snapshot.paramMap.get("id");
  }

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private tableSettingsService: TableSettingsService,
    @Optional() private remortgageDashboardService: RemortgageDashboardService,
    private actions$: Actions
  ) {
    this.remortgageDashboardService?.refreshJournal$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.tableSettingsService.refresh();
      });

    const refreshFor: ActionType[] = [
      Remortgage.AppointmentArranged,
      Mortgage.NoteSaved,
      Mortgage.PinnedNoteSaved,
      Mortgage.MessageSaved,
      Mortgage.TargetCompletionDateUpdated,
      Mortgage.TargetExchangeDateUpdated,
    ];
    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionSuccessful(...refreshFor))
      .subscribe(() => {
        this.tableSettingsService.refresh();
      });
  }

  public isEmail(entry: JournalEntry) {
    return entry.category === "EMAIL_IN" || entry.category === "EMAIL_OUT";
  }

  public isZoomSummary(entry: JournalEntry) {
    return entry.activity === "ZOOM_SUMMARY";
  }

  public ngOnInit() {
    this.initialize();
  }

  public trackByFn(index: number, item: JournalEntry) {
    return item.id;
  }

  public refresh(): void {
    this.initialized = false;
    this.destroy$.next(); // Complete existing subscriptions
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
    this.initialize();
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private async initialize() {
    this.tableSettingsService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: JournalEntry[]) => {
        this.items = data;
        this.initialized = true;
      });

    const dataSourceFactory: DataSourceFactory<MortgageTableParams> = (
      params
    ) => {
      params.pageSize = 10;
      params.sortBy = "createdDate";
      params.sortDirection = TableSorting.DESC;
      return this.dataSourceFactory(params);
    };

    this.tableSettingsService.setDataSourceFactory(dataSourceFactory);
  }
}
