<hf-custom-infobox
  [customerSentiment]="customerSentiment"
  [text]="'Overall meeting sentiment'"
  [title]="customerSentiment"
></hf-custom-infobox>

<hf-custom-infobox
  [icon]="'assets/icons/RealEstateAgent.svg'"
  [title]="'Sales Opportunity'"
  [text]="'Sales opportunity rating'"
  [rating]="salesPotentialRating"
  [isRatingVisible]="true"
></hf-custom-infobox>
