import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SummaryRatingEnum } from "../../models/full-call-details.dto";
import { ZoomCallSummaryService } from "../../services/zoom-call-summary.service";
import { Observable, throwError } from "rxjs";
import { ToastService } from "../../../../../projects/client/src/app/shared/services";
import { catchError } from "rxjs/operators";

@Component({
  selector: "hf-call-accuracy-rating",
  templateUrl: "./call-accuracy-rating.component.html",
  styleUrls: ["./call-accuracy-rating.component.scss"],
})
export class CallAccuracyRatingComponent {
  @Input() public callAccuracyRating: SummaryRatingEnum =
    SummaryRatingEnum.NotSet; // Default value

  @Input()
  public callDataId: number;
  @Output() callAccuracyRatingEmit = new EventEmitter<SummaryRatingEnum>();

  public SummaryRatingEnum = SummaryRatingEnum;
  public showFeedbackMessage = false;
  public fadeOutFeedbackMessage = false;

  constructor(
    private zoomCallSummaryService: ZoomCallSummaryService,
    private toastService: ToastService
  ) {}

  public callAccuracyRatingToggle(newRating: SummaryRatingEnum) {
    this.callAccuracyRating === newRating
      ? (this.callAccuracyRating = SummaryRatingEnum.NotSet)
      : (this.callAccuracyRating = newRating);

    this.callAccuracyRatingEmit.emit(this.callAccuracyRating);
    this.zoomCallSummaryService
      .rateZoomCallSummary(this.callDataId, this.callAccuracyRating)
      .pipe(catchError((error) => this.handleCallAccuraceError(error)))
      .subscribe();
    if (this.callAccuracyRating !== SummaryRatingEnum.NotSet) {
      this.showFeedbackMessage = true;
      this.fadeOutFeedbackMessage = false;

      // After 2 seconds, start the fade-out transition
      setTimeout(() => {
        this.fadeOutFeedbackMessage = true;

        // After the transition ends (1 second), hide the message completely
        setTimeout(() => {
          this.showFeedbackMessage = false;
        }, 1000); // Must match the CSS transition duration
      }, 2000);
    } else {
      // If the user unset the rating, hide the message
      this.showFeedbackMessage = false;
      this.fadeOutFeedbackMessage = false;
    }
  }

  private handleCallAccuraceError(error: any): Observable<never> {
    const message =
      error.status === 401 || error.status === 403
        ? "User is unauthorized to update feedback."
        : error.status === 404
        ? "Zoom AI summary not found."
        : error.status === 500
        ? "Internal Server Error - JSON processing failed."
        : "An error occurred while updating feedback.";
    this.toastService.add(message);
    return throwError(error);
  }
}
