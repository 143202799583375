<ng-container *ngIf="zoomCallSummary"
  ><div class="header">
    <div class="activity">
      <span>{{ zoomMeetingTitle }}</span>
    </div>

    <div class="date">
      {{ item.createdDate | date : "d MMM, y HH:mm" }}
    </div>
  </div>

  <div *ngIf="isWhite" class="triangle"></div>

  <section class="attendees-wrapper">
    <div class="attendees-wrapper_avatar">
      <img
        *ngIf="item.executor.photo"
        [hfSrcset]="
          item.executor.photo.folder + '/' + item.executor.photo.thumbnail
        "
        alt="{{ item.executor.fullName }} photo"
        class="avatar-img"
      />
      <img
        *ngIf="!item.executor.photo"
        src="/assets/img/avatar-placeholder.png"
        alt="{{ item.executor.fullName }} photo"
        class="avatar-img"
      />
    </div>
    <div class="attendees-wrapper_attendee-details">
      <span>{{ item.executor.fullName }}</span>
      <span class="host-attendee"
        ><span class="details-bolded">Host:</span> {{ getHostName() }}</span
      >
    </div>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="delete_outline"
      iconPos="center"
      (click)="onDeleteClicked()"
    >
    </hf-custom-button>
  </section>

  <section class="phone-details-wrapper avatar-margin">
    <div class="phone-details-wrapper_number">
      <i class="material-icons">phone</i>
      <span
        ><span class="details-bolded">Phone:</span>
        {{ zoomCallSummary.clientNumber }}</span
      >
    </div>
    <div class="phone-details-wrapper_duration">
      <i class="material-icons">access_time_filled</i>
      <span
        ><span class="details-bolded">Duration:</span> {{ getDuration() }}</span
      >
    </div>
  </section>

  <hf-custom-infobox-container
    class="avatar-margin"
    [customerSentiment]="zoomCallSummary.customerSentiment"
    [salesPotentialRating]="zoomCallSummary.salesPotential"
  ></hf-custom-infobox-container>

  <div
    *ngIf="callAccuracy === 'THUMBS_DOWN'"
    class="status-wrapper innaccurate"
  >
    Inaccurate summary
  </div>

  <section
    class="body-container"
    [class.hasClamp]="hasClampClass"
    [@clampExpandAnimation]="anim"
    (@clampExpandAnimation.done)="onClampDone()"
    (click)="onSeeZoomLengthToggle()"
    #bodyContainerEl
  >
    <section class="zoom-summary-text-container">
      <div class="separator">
        <h3 class="quick-recap">Quick Recap</h3>
        <p>{{ zoomCallSummary.quickRecap }}</p>
      </div>
      <ng-container *ngFor="let topic of zoomCallSummary.topicSummaries">
        <div class="separator">
          <h3>{{ topic.topic }}</h3>
          <p>{{ topic.summary }}</p>
        </div>
      </ng-container>
    </section>

    <ng-container *ngIf="zoomCallSummary.nextSteps.length > 0">
      <section class="zoom-summary-next-steps-container">
        <h3>Next Steps</h3>
        <p *ngFor="let step of zoomCallSummary.nextSteps">{{ step }}</p>
      </section>
    </ng-container>

    <hf-call-accuracy-rating
      [callAccuracyRating]="zoomCallSummary.summaryRating"
      [callDataId]="callDataId"
      (callAccuracyRatingEmit)="onCallAccuracyRatingEmitted($event)"
    ></hf-call-accuracy-rating>
  </section>

  <div class="footer">
    <hf-custom-button
      *ngIf="!isSeeLessVisible"
      type="button"
      class="button-primary"
      label="Draft for client"
      textPos="center"
      (onClick)="onDraftEmailForClients()"
    >
    </hf-custom-button>
    <span *ngIf="isSeeLessVisible"></span>
    <span
      (click)="onSeeZoomLengthToggle(true)"
      [class.hasClampFooter]="isSeeLessVisible"
      >{{ isSeeLessVisible ? "" : "See less" }}</span
    >
  </div>
</ng-container>
