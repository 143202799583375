import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";
import { takeUntil } from "rxjs/operators";
import { ToastService } from "../../../../../../projects/client/src/app/shared/services/toast.service";
import { UserSimple } from "../../../models";
import { Mortgage } from "../../../modules/mortgages/state/mortgage.actions";
import { CommunicationApiService } from "../../../services/communication-api.service";
import { UserApiService } from "../../../services/user-api.service";
import {
  ngIfAnimation,
  sidepaneMove2Cols,
} from "../../../shared/animations/sidepane-animation";
import {
  SidepaneHelper,
  SidepaneHost,
} from "../../../shared/sidepane/sidepane-helper.class";
import { Sidepane } from "../../../shared/sidepane/sidepane.decorator";
import { AddTaskService } from "../add-task/add-task.component";
import {
  ApplicantSelected,
  SharedSelectApplicantService,
} from "../select-applicant/select-applicant.component";
import { SelectUserService } from "../select-user/select-user.service";
import { ZoomCallSummaryService } from "../../../services/zoom-call-summary.service";
import { FullCallDetailsDTO } from "../../../models/full-call-details.dto";

const ChannelType = new Map<string, string>([
  ["SMS", "SMS"],
  ["EMAIL", "Email"],
]);

// NOTE: not needed - email clients do it
// const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
// function linkify(text: string): string {
//   return text.replace(urlRegex, function(url) {
//     return '<a href="' + url + '">' + url + '</a>';
//   });
// }

@Sidepane()
@Component({
  selector: "hf-send-message",
  templateUrl: "./send-message.component.html",
  styleUrls: ["./send-message.component.scss"],
  providers: [{ provide: SidepaneHost, useExisting: SendMessageComponent }],
  animations: [ngIfAnimation, sidepaneMove2Cols],
  host: { "[@ngIfAnimation]": "true" },
})
export class SendMessageComponent {
  public sh = new SidepaneHelper(this);

  public form = this.fb.group({
    subject: [null, Validators.required],
    body: [null, Validators.required],
    recipients: [null, Validators.required],
    ccEmail: [null],
    attachments: [null],
  });

  ccEmailLabel: string;
  staffUsers;
  zoomContent?: FullCallDetailsDTO;
  zoomMessageState: boolean = false;
  public ChannelType = ChannelType;
  public get id() {
    return this.route.snapshot.paramMap.get("id");
  }
  public get isMortgage() {
    return this.route.snapshot.data.isMortgage;
  }
  public get isZoomAIMeetingSummary() {
    return this.router.getCurrentNavigation()?.extras?.state?.isZoom;
  }

  constructor(
    private store: Store,
    private actions: Actions,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private communicationApiService: CommunicationApiService,
    private selectApplicantService: SharedSelectApplicantService,
    private toastService: ToastService,
    private selectUserService: SelectUserService,
    private userApiService: UserApiService,
    private zoomCallSummaryService: ZoomCallSummaryService
  ) {
    this.setup();
    if (this.isZoomAIMeetingSummary) {
      this.zoomMessageState = true;
      this.zoomCallSummaryService.zoomAISummary$.subscribe((data) => {
        this.zoomContent = data.zoomSummary;
        const zoomBodyContent = this.extractTopicSummaries(data.zoomSummary);

        this.form.patchValue({ body: zoomBodyContent });
        this.form.patchValue({
          subject: `${data.subjectTitle}`,
        });
      });
    }
  }

  public onSelectApplicantsClicked() {
    this.selectApplicantService.initial = this.sh.value.recipients;
    this.router.navigate(["selectApplicant"], { relativeTo: this.route });
  }

  public onSelectCcEmailClicked() {
    this.selectUserService.initial = this.sh.value.ccEmail;
    this.selectUserService.multi = true;
    this.selectUserService.detailed = true;
    this.selectUserService.users$ = this.staffUsers;
    this.router.navigate(["selectUser"], { relativeTo: this.route });
  }

  public async spOnSubmit() {
    this.sh.isSubmitting = true;
    console.log(this.zoomMessageState);
    try {
      this.isMortgage
        ? await this.communicationApiService.sendEmailMortgage(
            this.id,
            this.sh.value
          )
        : await this.communicationApiService.sendEmailProtection(
            this.id,
            this.sh.value
          );

      this.toastService.add("Message saved");
      this.store.dispatch(new Mortgage.MessageSaved());
      this.sh.goBack();
    } catch (e) {
      this.toastService.add("Failed to send message", "error", e);
    }

    this.sh.isSubmitting = false;
  }

  private setup() {
    this.actions
      .pipe(takeUntil(this.sh.destroy$), ofActionSuccessful(ApplicantSelected))
      .subscribe(({ applicants }) => {
        this.form.patchValue({ recipients: applicants });
      });
    this.selectUserService.userSelected$
      .pipe(takeUntil(this.sh.destroy$))
      .subscribe((userSelected: UserSimple[]) => {
        this.sh.value.ccEmail = userSelected;
        this.form.patchValue({ ccEmail: userSelected });
        this.ccEmailLabel = this.sh.value.ccEmail
          .map((t) => t.fullName)
          .join(", ");
      });
    const params = {
      pageSize: 9999,
      page: 1,
      sortBy: "firstName;lastName",
      sortDirection: "ASC",
    };
    this.userApiService
      .listHeronUsers(params)
      .pipe(takeUntil(this.sh.destroy$))
      .subscribe((res) => {
        this.staffUsers = res.content;
      });
  }

  private extractTopicSummaries(details: FullCallDetailsDTO): string {
    const parts: string[] = [];

    // Quick Recap
    if (details.quickRecap) {
      parts.push(`<h3>Quick Recap</h3>${details.quickRecap}`);
    }

    // Add each Topic and Summary
    if (details.topicSummaries && details.topicSummaries.length > 0) {
      details.topicSummaries.forEach((topic) => {
        parts.push(`<h3>${topic.topic}:</h3>${topic.summary}`);
      });
    }

    // Add Next Steps if present
    if (details.nextSteps && details.nextSteps.length > 0) {
      parts.push(
        "<h3>Next Steps</h3>" +
          details.nextSteps.map((step) => `- ${step}`).join("<br>")
      );
    }
    return parts.join("<br><br>");
  }
}
