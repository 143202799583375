<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Update Status</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner
      *ngIf="isLoading"
      sidepaneLoading="true"
    ></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <div class="hf-selector">
        <label>Status</label>
        <div
          *ngIf="!state"
          class="hf-selector-container hf-selector-has-value"
          [routerLink]="['./selectStatus']"
        >
          <div class="hf-selector-label-area">
            <div class="title">Select Status</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>

        <ng-container *ngIf="state">
          <div
            class="hf-selector-container hf-selector-has-value hf-selector-black-color"
            [routerLink]="['./selectStatus']"
          >
            <div class="hf-selector-content-area">
              <div class="title">
                {{ APPLICATION_STATE_NAMES.get(state) }}
              </div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <!-- Not proceeding reasons list -->
      <div
        class="hf-selector"
        *ngIf="state === 'NOT_PROCEEDING' && !isRemortgageDashboard"
      >
        <label>Not Proceeding Reason</label>
        <div
          *ngIf="!notProceedingReason && !isRemortgageDashboard"
          class="hf-selector-container hf-selector-has-value"
          [routerLink]="['./selectNotProceedingReason']"
        >
          <div class="hf-selector-label-area">
            <div class="title">Select Not Proceeding Reason</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>

        <ng-container *ngIf="notProceedingReason">
          <div
            class="hf-selector-container hf-selector-has-value hf-selector-black-color"
            [routerLink]="['./selectNotProceedingReason']"
          >
            <div class="hf-selector-content-area">
              <div class="title">
                {{ notProceedingReason }}
              </div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="enabled('declinedReasonId')"
        class="ev-form-field"
        [hfFormField]="control('declinedReasonId')"
      >
        <label>Declining Reason</label>
        <hf-selector-link
          placeholder="Select Declining Reason"
          [value]="
            value?._declinedReason?.other ||
            value?._declinedReason?.followup?.displayName
          "
          [isInvalid]="control('declinedReasonId').invalid && isSubmitted"
          (click)="onSelectDecliningReasonClicked()"
        >
        </hf-selector-link>
      </div>

      <!-- VALUATION_RECEIVED || VALUATION_BOOKED -->
      <ng-container
        *ngIf="state === 'VALUATION_RECEIVED' || state === 'VALUATION_BOOKED'"
      >
        <div
          class="hf-selector desktop-date-time"
          [hfFormField]="control('valuationDate')"
        >
          <label>Valuation Date</label>

          <div
            *ngIf="!form.get('valuationDate').value"
            class="hf-selector-container hf-selector-has-value"
            [class.invalid]="control('valuationDate').invalid && isSubmitted"
            [routerLink]="['./selectDateAndTime']"
            [queryParams]="{
              name: 'valuationDate',
              onlyDate: 'true',
              maxDate: state === 'VALUATION_RECEIVED' ? now : undefined
            }"
          >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('valuationDate').value">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectDateAndTime']"
              [queryParams]="{
                name: 'valuationDate',
                onlyDate: 'true',
                maxDate: state === 'VALUATION_RECEIVED' ? now : undefined
              }"
            >
              <div class="hf-selector-content-area">
                <div class="title">
                  {{ form.get("valuationDate").value | date : "d MMM y" }}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <!-- mobile Valuation Date -->
        <div
          class="flex-space-between-layout native-mobile-date-time"
          style="margin-bottom: 12px"
        >
          <label>Valuation Date</label>
          <hf-custom-native-mobile-datetime
            formControlName="valuationDate"
            onlyDate="true"
            [maxDate]="state === 'VALUATION_RECEIVED' ? nowNative : undefined"
            [invalid]="form.get('valuationDate').errors?.maxDate"
            class="native-mobile-date-time"
          >
          </hf-custom-native-mobile-datetime>
        </div>

        <ng-container *ngIf="state === 'VALUATION_RECEIVED'">
          <div
            class="ev-form-field"
            style="margin-top: 20px"
            [hfFormField]="control('exchangedDate')"
          >
            <label>Valuation Amount</label>
            <hfc-text-input
              prefix="£"
              [disableDecimal]="true"
              [currency]="true"
              formControlName="valuationAmount"
              [hfFormField]="control('valuationAmount')"
            ></hfc-text-input>
          </div>
        </ng-container>
      </ng-container>

      <!-- COMPLETED -->
      <ng-container *ngIf="state === 'COMPLETED'">
        <div
          class="hf-selector desktop-date-time"
          [hfFormField]="control('completionDate')"
        >
          <label>Completion Date</label>
          <div
            *ngIf="!form.get('completionDate').value"
            class="hf-selector-container hf-selector-has-value"
            [routerLink]="['./selectDateAndTime']"
            [queryParams]="{
              name: 'completionDate',
              onlyDate: 'true',
              maxDate: now
            }"
          >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('completionDate').value">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectDateAndTime']"
              [queryParams]="{
                name: 'completionDate',
                onlyDate: 'true',
                maxDate: now
              }"
            >
              <div class="hf-selector-content-area">
                <div class="title">
                  {{ form.get("completionDate").value | date : "d MMM y" }}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <!-- mobile Completion Date -->
        <div
          class="flex-space-between-layout native-mobile-date-time"
          style="margin-bottom: 12px"
        >
          <label>Completion Date</label>
          <hf-custom-native-mobile-datetime
            formControlName="completionDate"
            onlyDate="true"
            [maxDate]="nowNative"
            [invalid]="form.get('completionDate').errors?.maxDate"
            class="native-mobile-date-time"
          >
          </hf-custom-native-mobile-datetime>
        </div>

        <div
          class="hf-selector desktop-date-time"
          [hfFormField]="control('productEndDate')"
        >
          <label>Product End Date</label>

          <div
            *ngIf="!form.get('productEndDate').value"
            class="hf-selector-container hf-selector-has-value"
            [routerLink]="['./selectDateAndTime']"
            [queryParams]="{ name: 'productEndDate', onlyDate: 'true' }"
          >
            <div class="hf-selector-label-area">
              <div class="title">Select Date</div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>

          <ng-container *ngIf="form.get('productEndDate').value">
            <div
              class="hf-selector-container hf-selector-has-value hf-selector-black-color"
              [routerLink]="['./selectDateAndTime']"
              [queryParams]="{ name: 'productEndDate', onlyDate: 'true' }"
            >
              <div class="hf-selector-content-area">
                <div class="title">
                  {{ form.get("productEndDate").value | date : "d MMM y" }}
                </div>
              </div>
              <i class="material-icons chevron-icon">chevron_right</i>
            </div>
          </ng-container>
        </div>

        <!-- mobile Product End Date -->
        <div
          class="flex-space-between-layout native-mobile-date-time"
          style="margin-bottom: 12px"
        >
          <label>Product End Date</label>
          <hf-custom-native-mobile-datetime
            formControlName="productEndDate"
            onlyDate="true"
            [invalid]="form.get('productEndDate').errors?.minDate"
            class="native-mobile-date-time"
          >
          </hf-custom-native-mobile-datetime>
        </div>
      </ng-container>

      <!-- Offer Expiry Date -->
      <hf-date-picker
        *ngIf="state === 'MORTGAGE_OFFER_ISSUED'"
        formControlName="offerExpiryDate"
        label="Offer Expiry Date"
        propertyName="offerExpiryDate"
        [onlyDate]="true"
        class="desktop-date-time"
        [isInvalid]="control('offerExpiryDate') && isSubmitted"
        [hfFormField]="control('offerExpiryDate')"
      ></hf-date-picker>

      <!-- mobile Offer Expiry Date -->
      <div
        *ngIf="state === 'MORTGAGE_OFFER_ISSUED'"
        class="flex-space-between-layout native-mobile-date-time"
        style="margin-bottom: 12px"
      >
        <label>Offer Expiry Date</label>
        <hf-custom-native-mobile-datetime
          formControlName="offerExpiryDate"
          onlyDate="true"
          [invalid]="form.get('offerExpiryDate').errors?.minDate"
          class="native-mobile-date-time"
        >
        </hf-custom-native-mobile-datetime>
      </div>

      <!-- EXCHANGED -->
      <ng-container *ngIf="state === 'EXCHANGED'">
        <div
          class="ev-form-field"
          style="margin-top: 20px"
          [hfFormField]="control('exchangedDate')"
        >
          <label>Date of Exchange</label>
          <hf-date-input
            formControlName="exchangedDate"
            propertyName="exchangedDate"
            minDate="none"
            [maxDate]="nowNative"
            [invalid]="control('exchangedDate').invalid && isSubmitted"
          ></hf-date-input>
        </div>

        <div
          class="ev-form-field"
          [hfFormField]="control('targetCompletionDate')"
        >
          <label>Target Completion Date</label>
          <hf-date-input
            formControlName="targetCompletionDate"
            propertyName="targetCompletionDate"
            [invalid]="control('targetCompletionDate').invalid && isSubmitted"
          ></hf-date-input>
        </div>
      </ng-container>

      <div
        *ngIf="enabled('lenderReference')"
        class="ev-form-field"
        [hfFormField]="control('lenderReference')"
      >
        <label>Lender Reference</label>
        <hfc-text-input formControlName="lenderReference"></hfc-text-input>

        <div
          *ngIf="form.get('lenderReference').errors?.pattern"
          class="hf-invalid-error"
          style="margin-top: 8px"
        >
          Lender reference format invalid
        </div>
      </div>

      <div
        *ngIf="enabled('propCodeId')"
        class="ev-form-field"
        [hfFormField]="control('propCodeId')"
      >
        <label>L&G Prop Code</label>
        <hf-selector-link
          placeholder="Select L&G Prop Code"
          [value]="getPropcoValue()"
          [isInvalid]="control('propCodeId').invalid && isSubmitted"
          (click)="onSelectPropcodeClicked()"
        >
        </hf-selector-link>
      </div>

      <label>Private Notes (Optional)</label>
      <hf-textarea
        [group]="form.get('journal')"
        inputFormControlName="internalNotes"
        [smaller]="true"
      ></hf-textarea>

      <!-- MORTGAGE_OFFER_ISSUED -->
      <ng-container *ngIf="state === 'MORTGAGE_OFFER_ISSUED'">
        <div
          class="ev-form-field"
          *ngIf="form.get('commission').enabled"
          [hfFormField]="control('commission')"
        >
          <label>Expected Commission</label>
          <hfc-text-input
            prefix="£"
            [currency]="true"
            [disableDecimal]="true"
            formControlName="commission"
          ></hfc-text-input>
        </div>

        <label>Mortgage Offer</label>
        <div
          class="flex-space-between-layout"
          style="width: 100%; margin-bottom: 12px"
        >
          <hf-fileuploader
            controlName="mortgageOffer"
            [group]="form"
            [fileControl]="form.value['mortgageOffer']"
          >
          </hf-fileuploader>
        </div>

        <label
          >Key Facts Illustration (KFI)
          {{
            form.get("kfiDocument.filename").validator ? "" : "(Optional)"
          }}</label
        >
        <div
          class="flex-space-between-layout"
          style="width: 100%; margin-bottom: 12px"
        >
          <hf-fileuploader
            controlName="kfiDocument"
            [group]="form"
            [fileControl]="form.value['kfiDocument']"
          >
          </hf-fileuploader>
        </div>
      </ng-container>

      <!-- Notify Client / Message / Document -->
      <div
        *ngIf="form.get('journal.notifyClient').enabled"
        formGroupName="journal"
        class="flex-space-between-layout"
        style="width: 100%; margin-bottom: 8px"
      >
        <hf-checkbox
          formControlName="notifyClient"
          (valueChanged)="setupDocumentAttachments()"
          label="Notify Client"
        ></hf-checkbox>
      </div>

      <ng-container *ngIf="form.get('journal.connectedDocument').enabled">
        <label
          >Message To Client
          {{ state === "MORTGAGE_OFFER_ISSUED" ? "(Optional)" : "" }}</label
        >
        <hf-textarea
          [group]="form.get('journal')"
          inputFormControlName="messageToClient"
          [smaller]="true"
        ></hf-textarea>

        <div
          class="flex-space-between-layout"
          style="width: 100%; margin-bottom: 8px"
        >
          <hf-fileuploader
            controlName="attachment"
            [group]="form.get('journal.connectedDocument')"
            [fileControl]="
              form.get('journal.connectedDocument').value['attachment']
            "
          >
          </hf-fileuploader>
        </div>
      </ng-container>

      <div
        *ngIf="form.get('completionDate')?.errors?.maxDate"
        class="hf-invalid-error"
      >
        Comletion Date cannot be in the future.
      </div>
      <div
        *ngIf="form.get('valuationDate')?.errors?.minDate"
        class="hf-invalid-error"
      >
        Valuation Date cannot be in the past.
      </div>
      <div
        *ngIf="form.get('valuationDate')?.errors?.maxDate"
        class="hf-invalid-error"
      >
        Valuation Date cannot be in the future.
      </div>
      <div
        *ngIf="form.get('productEndDate')?.errors?.minDate"
        class="hf-invalid-error"
      >
        Product End Date cannot be in the past.
      </div>
      <div
        *ngIf="form.get('offerExpiryDate')?.errors?.minDate"
        class="hf-invalid-error"
      >
        Offer Expiry Date cannot be in the past.
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Update Status"
      [isLoading]="isSubmitting"
      (onClick)="onSubmit()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
