<hf-journal-loader *ngIf="!initialized"></hf-journal-loader>

<div *ngIf="initialized" [@fade]>
  <hf-journal-item-container
    *ngFor="
      let item of items;
      trackBy: trackByFn;
      let first = first;
      let last = last
    "
    [item]="item"
    [first]="first"
    [last]="last"
  >
    <hf-journal-item
      *ngIf="!isEmail(item) && !isZoomSummary(item)"
      [item]="item"
    ></hf-journal-item>
    <hf-message-journal-item
      *ngIf="isEmail(item) && !isZoomSummary(item)"
      [item]="item"
    ></hf-message-journal-item>
    <hf-zoom-call-summary-journal-item
      *ngIf="!isEmail(item) && isZoomSummary(item)"
      [item]="item"
      [zoomMeetingTitle]="item.summary"
    ></hf-zoom-call-summary-journal-item>
  </hf-journal-item-container>
</div>

<hf-infinite-scroll-trigger></hf-infinite-scroll-trigger>
