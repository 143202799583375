import { A11yModule } from "@angular/cdk/a11y";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ClientSharedModule } from "../../../../projects/client/src/app/shared/client-shared.module";
import { MarginElementDirective } from "../directives/margin-element.directive";
import { ValidateOnBlurDirective } from "../directives/validate-onblur.directive";
import { VarDirective } from "../directives/var.directive";
import { ClickAwayDirective } from "../utils/click-away.directive";
import { InputFieldTextSearchPipe } from "../utils/pipes/input-field-filter.pipe";
import { MortgageStatusPipe } from "../utils/pipes/mortgage-status.pipe";
import { TimeDifferencePipe } from "../utils/pipes/time-difference.pipe";
import { SidepaneDirective } from "../utils/sidepane.directive";
import { AvatarComponent } from "./avatar/avatar.component";
import { BannerWithImageComponent } from "./banner-with-image/banner-with-image.component";
import { CardWithAddButtonComponent } from "./card-with-add-button/card-with-add-button.component";
import { CustomNativeMobileDatetimeComponent } from "./custom-native-mobile-datetime/custom-native-mobile-datetime.component";
import { CustomSpinnerModule } from "./custom-spinner/custom-spinner.module";
import { DateInputComponent } from "./date-input/date-input.component";
import { DatePickerModule } from "./date-picker/date-picker.module";
import { DetailsContainerModule } from "./details-container/details-container.module";
import { StickyTableHeadingsDirective } from "./directives/sticky-table-headings.directive";
import { TooltipDirective } from "./directives/tooltip.directive";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { EditChangesGroupComponent } from "./edit-changes-group/edit-changes-group.component";
import { EvergladesSharedMorule } from "./everglades-shared.module";
import { ExpanderComponent } from "./expander/expander.component";
import { FactFindStatusBannerComponent } from "./fact-find-status-banner/fact-find-status-banner.component";
import { FactFindStepStatusIconComponent } from "./fact-find-step-status-icon/fact-find-step-status-icon.component";
import { FileInputPreviewComponent } from "./file-input/file-input-preview/file-input-preview.component";
import { FileInputComponent } from "./file-input/file-input.component";
import { IconButtonComponent } from "./icon-button/icon-button.component";
import { NavButtonModule } from "./nav-button/nav-button.module";
import { NavigationComponent } from "./navigation/navigation.component";
import { SelectorLinkComponent } from "./selector-link/selector-link.component";
import { StatusIconComponent } from "./status-icon/status-icon.component";
import { TabGroupComponent } from "./tab-group/tab-group.component";
import { TabPanelComponent } from "./tab-panel/tab-panel.component";
import { TabSectionComponent } from "./tab-section/tab-section.component";
import { TableSettingsService } from "./table-utils/table-settings.service";
import { TableSortingDirective } from "./table-utils/table-sorting.directive";
import { TableDirective } from "./table-utils/table.directive";
import { ToggleComponent } from "./toggle/toggle.component";
import { TooltipTextContentComponent } from "./tooltip-text-content/tooltip-text-content.component";
import { TwoFactorStatusComponent } from "./two-factor-status/two-factor-status.component";
import { ExportButtonComponent } from "./export-button/export-button.component";
import { ClientImportedDataComponent } from "./client-imported-data/client-imported-data.component";
import { DecimalComponent } from "./decimal/decimal.component";
import { FilteringBadgesComponent } from "./filtering-badges/filtering-badges.component";
import { FilterComposerComponent } from "./filter-composer/filter-composer.component";
import { FilterComposerListComponent } from "./filter-composer/filter-composer-list/filter-composer-list.component";
import { CustomSelectorModule } from "./custom-selector/custom-selector.module";
import { OpenFilterComposerButtonComponent } from "./open-filter-composer-button/open-filter-composer-button.component";
import { FilterComposerToggleComponent } from "./filter-composer/filter-composer-toggle/filter-composer-toggle.component";
import { FilterComposerDateComponent } from "./filter-composer/filter-composer-date/filter-composer-date.component";
import { ClientCategoryIconComponent } from "./client-category-icon/client-category-icon.component";
import { CustomTextFieldModule } from "./custom-text-field/custom-text-field.module";
import { ToolbarButtonComponent } from "./toolbar-button/toolbar-button.component";
import { ToolbarButtonContainerComponent } from "./toolbar-button-container/toolbar-button-container.component";
import { MilestoneTimelineComponent } from "./milestone-timeline/milestone-timeline.component";
import { CurrencyComponent } from "./currency/currency.component";
import { ProductEndDateComponent } from "./product-end-date/product-end-date.component";
import { ProjectedIncreaseComponent } from "./projected-increase/projected-increase.component";
import { JournalComponent } from "./journal/journal.component";
import { JournalItemContainerComponent } from "./journal-item-container/journal-item-container.component";
import { JournalItemComponent } from "./journal-item/journal-item.component";
import { PinnedNoteBoxComponent } from "./pinned-note-box/pinned-note-box.component";
import { InfiniteScrollTriggerComponent } from "./infinite-scroll-trigger/infinite-scroll-trigger.component";
import { JournalLoaderComponent } from "./journal-loader/journal-loader.component";
import { ToolbarWithMenuComponent } from "./toolbar-with-menu/toolbar-with-menu.component";
import { CustomMenuComponent } from "./custom-menu/custom-menu.component";
import { IfPermissionModule } from "../directives/if-permission/if-permission.module";
import { SidepaneComponent } from "./sidepane/sidepane.component";
import { CustomButtonModule } from "./custom-button/custom-button.module";
import { Office365StatusComponent } from "./office-365-status/office-365-status.component";
import { MessageJournalItemComponent } from "./message-journal-item/message-journal-item.component";
import { EmailEventsComponent } from "./email-events/email-events.component";
import { MessageJournalItemMessageComponent } from "./message-journal-item/message-journal-item-message/message-journal-item-message.component";
import { EmailAttachmentsComponent } from "./email-attachments/email-attachments.component";
import { SizePipe } from "../utils/pipes/size.pipe";
import { DragoverDirective } from "./fileuploader/dragover/dragover.directive";
import { FileuploaderComponent } from "./fileuploader/fileuploader.component";
import { FilePreviewComponent } from "./fileuploader/file-preview/file-preview.component";
import { SidepaneNavigationComponent } from "./sidepane-navigation/sidepane-navigation.component";
import { LinkListModule } from "./link-list/link-list.module";
import { LinkButtonComponent } from "./link-button/link-button.component";
import { HandoverDropdownComponent } from "../modules/mortgages/components/handover-dropdown/handover-dropdown.component";
import { WarningBadgeComponent } from "./warning-badge/warning-badge.component";
import { BlueLinkButtonComponent } from "./blue-link-button/blue-link-button.component";
import { MidasFailedBadgeComponent } from "./midas-failed-badge/midas-failed-badge.component";
import { CustomInfoboxComponent } from "./custom-infobox/custom-infobox.component";
import { StarRatingComponent } from "./star-rating/star-rating.component";
import { CustomInfoboxContainerComponent } from "./custom-infobox-container/custom-infobox-container.component";
import { ZoomCallSummaryJournalItemComponent } from "./zoom-call-summary-journal-item/zoom-call-summary-journal-item.component";
import { CallAccuracyRatingComponent } from "./call-accuracy-rating/call-accuracy-rating.component";
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";

const entryComponents = [TooltipTextContentComponent];

const exported = [
  ValidateOnBlurDirective,
  TableSortingDirective,
  VarDirective,
  DragoverDirective,
  TableDirective,
  MarginElementDirective,
  TimeDifferencePipe,
  InputFieldTextSearchPipe,
  SidepaneDirective,
  ClickAwayDirective,
  MortgageStatusPipe,
  SizePipe,
  SelectorLinkComponent,
  FactFindStatusBannerComponent,
  FactFindStepStatusIconComponent,
  CardWithAddButtonComponent,
  TabGroupComponent,
  TabPanelComponent,
  TabSectionComponent,
  IconButtonComponent,
  TooltipDirective,
  StatusIconComponent,
  CustomNativeMobileDatetimeComponent,
  DateInputComponent,
  FileInputComponent,
  FileInputPreviewComponent,
  BannerWithImageComponent,
  StickyTableHeadingsDirective,
  AvatarComponent,
  DropdownComponent,
  ExpanderComponent,
  ToggleComponent,
  EditChangesGroupComponent,
  NavigationComponent,
  TwoFactorStatusComponent,
  Office365StatusComponent,
  ExportButtonComponent,
  ClientImportedDataComponent,
  DecimalComponent,
  CurrencyComponent,
  FilteringBadgesComponent,
  FilterComposerComponent,
  FilterComposerListComponent,
  FilterComposerToggleComponent,
  FilterComposerDateComponent,
  OpenFilterComposerButtonComponent,
  ClientCategoryIconComponent,
  ToolbarButtonComponent,
  ToolbarButtonContainerComponent,
  MilestoneTimelineComponent,
  ProductEndDateComponent,
  ProjectedIncreaseComponent,
  JournalComponent,
  JournalItemContainerComponent,
  JournalItemComponent,
  MessageJournalItemComponent,
  MessageJournalItemMessageComponent,
  PinnedNoteBoxComponent,
  InfiniteScrollTriggerComponent,
  JournalLoaderComponent,
  ToolbarWithMenuComponent,
  CustomMenuComponent,
  SidepaneComponent,
  EmailEventsComponent,
  EmailAttachmentsComponent,
  FileuploaderComponent,
  FilePreviewComponent,
  SidepaneNavigationComponent,
  LinkButtonComponent,
  BlueLinkButtonComponent,
  HandoverDropdownComponent,
  WarningBadgeComponent,
  MidasFailedBadgeComponent,
  ZoomCallSummaryJournalItemComponent,
  CallAccuracyRatingComponent,
  CustomInfoboxComponent,
  StarRatingComponent,
  CustomInfoboxContainerComponent,
  ...entryComponents,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EvergladesSharedMorule,
    FormsModule,
    ReactiveFormsModule,
    PortalModule,
    DetailsContainerModule,
    DatePickerModule,
    CustomSpinnerModule,
    ClientSharedModule,
    CustomSelectorModule,
    CustomTextFieldModule,
    IfPermissionModule,
    CustomButtonModule,
    LinkListModule,
  ],
  declarations: exported,
  exports: [
    ...exported,
    NavButtonModule,
    DetailsContainerModule,
    DatePickerModule,
    CustomCurrencyPipe,
  ],
  entryComponents,
  providers: [TableSettingsService, A11yModule, MortgageStatusPipe],
})
export class SharedModule {}
