<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Create Application</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner
      *ngIf="isLoading"
      sidepaneLoading="true"
    ></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form">
      <!-- Lender name-->
      <div>
        <label>Lender</label>

        <div *ngIf="handovers?.length === 1" class="hf-form-static-value">
          <div class="title">{{ value?._handover.lender }}</div>
        </div>

        <hf-selector-link
          *ngIf="handovers?.length > 1"
          placeholder="Select Lender"
          [value]="value._handover?.lender"
          (click)="onSelectLender()"
          style="display: block; margin-bottom: 12px"
        >
        </hf-selector-link>

        <div *ngIf="value?._handover?.loanAmount">
          <label>Loan Amount</label>
          <div class="hf-form-static-value">
            {{
              value?._handover?.loanAmount
                | customCurrency : "GBP" : true : "1.0-2"
            }}
          </div>
        </div>

        <label>Lender Reference (If Known)</label>
        <hf-custom-text-field
          [group]="form"
          inputFormControlName="lenderReference"
        >
        </hf-custom-text-field>

        <div
          *ngIf="form.get('lenderReference').errors?.pattern"
          class="hf-invalid-error"
          style="margin-top: 8px"
        >
          Lender reference format invalid
        </div>

        <div *ngIf="form.get('loanAmount').enabled" class="loanAmount">
          <label>Loan Amount</label>
          <hfc-text-input
            prefix="£"
            [disableDecimal]="true"
            [currency]="true"
            formControlName="loanAmount"
          ></hfc-text-input>
        </div>
      </div>

      <!-- Select case progression -->
      <div class="hf-selector">
        <label>Case Progression</label>
        <div
          *ngIf="!form.get('caseProgression.id').value"
          class="hf-selector-container hf-selector-has-value"
          [routerLink]="['./assignCaseProgression']"
        >
          <div class="hf-selector-label-area">
            <div class="title">Select Case Progression</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>

        <ng-container *ngIf="form.get('caseProgression.id').value">
          <div
            class="hf-selector-container hf-selector-has-value hf-selector-black-color"
            [routerLink]="['./assignCaseProgression']"
          >
            <div class="hf-selector-content-area">
              <div class="title">
                {{ form.get("caseProgression.fullName").value }}
              </div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <!-- Select Solicitor -->
      <div *ngIf="form.get('solicitor').enabled" class="hf-selector">
        <label>Solicitor</label>

        <div
          class="flex-space-between-layout"
          style="width: 100%; margin-bottom: 8px"
        >
          <hf-checkbox
            formControlName="freeLegalSolicitor"
            label="Free Solicitor"
            (valueChanged)="onFreeLegalSolicitorChange($event)"
          ></hf-checkbox>
        </div>

        <div
          *ngIf="!form.get('solicitor.name').value"
          class="hf-selector-container hf-selector-has-value"
          [routerLink]="['./selectSolicitor']"
        >
          <div class="hf-selector-label-area">
            <div class="title">Select Solicitor</div>
          </div>
          <i class="material-icons chevron-icon">chevron_right</i>
        </div>
        <ng-container *ngIf="form.get('solicitor.name').value">
          <div
            class="hf-selector-container hf-selector-has-value hf-selector-black-color"
            [routerLink]="['./selectSolicitor']"
          >
            <div class="hf-selector-content-area">
              <div class="title">{{ form.get("solicitor.name").value }}</div>
              <div class="description">
                {{ form.get("solicitor.email").value }}
              </div>
              <div class="description-extended">
                {{ form.get("solicitor.phone").value }}
              </div>
            </div>
            <i class="material-icons chevron-icon">chevron_right</i>
          </div>
        </ng-container>
      </div>

      <div class="ev-form-field">
        <label>Do you know the commission amount?</label>
        <hfc-yes-no-checkbox
          formControlName="_hasCommission"
        ></hfc-yes-no-checkbox>
      </div>

      <div class="ev-form-field" *ngIf="form.get('commission').enabled">
        <label>Expected Commission</label>
        <hfc-text-input
          prefix="£"
          [disableDecimal]="true"
          [currency]="true"
          formControlName="commission"
        ></hfc-text-input>
      </div>

      <label>Private Notes (Optional)</label>
      <hf-textarea
        [group]="form.get('journal')"
        type="text"
        inputFormControlName="internalNotes"
        fieldName="internalNotes"
        [smaller]="true"
      >
      </hf-textarea>

      <ng-container *ngIf="form.get('journal.connectedDocument').enabled">
        <label>Message To Client (Optional)</label>
        <hf-textarea
          [group]="form.get('journal')"
          inputFormControlName="messageToClient"
          fieldName="messageToClient"
          [smaller]="true"
        ></hf-textarea>

        <div
          class="flex-space-between-layout"
          style="margin-bottom: 8px"
          [style.width.%]="100"
        >
          <hf-fileuploader
            controlName="attachment"
            [group]="form.get('journal.connectedDocument')"
            [fileControl]="
              form.get('journal.connectedDocument').value['attachment']
            "
          >
          </hf-fileuploader>
        </div>
      </ng-container>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hf-custom-button
      type="submit"
      class="button-primary"
      label="Create Application"
      [disabled]="form.invalid"
      [callable]="true"
      (onClick)="onCreate()"
    >
    </hf-custom-button>

    <hf-custom-button
      type="button"
      class="button-secondary"
      label="Cancel"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
