<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content">
  <div class="flex-space-between-layout template-header">
    <h1>Net Monthly Income</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <form [formGroup]="form">
      <ng-container formArrayName="items">
        <div
          *ngFor="let applicant of applicantsIncomes; let i = index"
          class="applicant"
          formGroupName="{{ i }}"
        >
          <div class="applicant__name">{{ applicant.applicant.fullName }}</div>
          <div class="applicant__hint">Annual Income Breakdown</div>
          <div
            *ngFor="let income of applicant.incomes"
            class="applicant__income"
          >
            {{ income.description }}
            <span class="applicant__income__amount">
              {{ income.amount | currency : "GBP" : undefined }}
            </span>
          </div>

          <div class="applicant__income">
            Total
            <span class="applicant__income__amount">
              {{ applicant.total | currency : "GBP" : undefined }}
            </span>
          </div>

          <hfc-text-input
            [currency]="true"
            prefix="£"
            [disableDecimal]="true"
            sufix="per month"
            formControlName="amount"
          ></hfc-text-input>
        </div>
      </ng-container>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button type="primary" [disabled]="form.invalid" (click)="onSubmit()"
      >Save</hfc-button
    >
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>
  </div>
</div>
