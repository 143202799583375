import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  FullCallDetailsDTO,
  SummaryRatingEnum,
} from "../models/full-call-details.dto";

@Injectable({
  providedIn: "root",
})
export class ZoomCallSummaryService {
  private _zoomAISummary = new BehaviorSubject<
    { subjectTitle: string; zoomSummary: FullCallDetailsDTO } | undefined
  >(undefined);

  public zoomAISummary$ = this._zoomAISummary.asObservable();
  public get zoomAISummary() {
    return this._zoomAISummary.value;
  }
  public setZoomAISummary(subjectTitle: string, summary: FullCallDetailsDTO) {
    this._zoomAISummary.next({ subjectTitle, zoomSummary: summary });
  }

  private readonly API_NAME: string = "zoomSummary";

  constructor(private readonly apiService: ApiService) {}

  public loadZoomCallSummary(
    callDataId: number
  ): Observable<FullCallDetailsDTO> {
    const params = { callDataId };
    return this.apiService.get(this.API_NAME, "fetchFullCallDetails", {
      params,
    });
  }

  public rateZoomCallSummary(
    callDataId: number,
    rating: SummaryRatingEnum
  ): Observable<FullCallDetailsDTO> {
    const params = { callDataId, rating };
    return this.apiService.post(this.API_NAME, "rateCallSummary", params);
  }

  public deleteZoomCallSummary(
    callDataId: number,
    journalEntryId: number
  ): Observable<FullCallDetailsDTO> {
    const params = { callDataId, journalEntryId };
    return this.apiService.delete(this.API_NAME, "deleteCallSummary", {
      params,
    });
  }
}
