<form [formGroup]="form" class="ev-sidepane-form">
  <div class="ev-sidepane-form-section">
    <!-- <div class="ev-sidepane-form-heading">General Notes</div> -->

    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('productTerm')" class="ev-form-field">
          <label>New Monthly Income</label>
          <hf-card-with-add-button
            [hasValue]="form.get('netMonthlyIncomes').value"
            (click)="onMonthlyIncomeClicked()"
            >Add Net Monthly Income
            <ng-template #hasValueTemplate>
              <div class="net-income-card">
                <div
                  *ngFor="let income of form.get('netMonthlyIncomes').value"
                  class="net-income-card__applicant"
                >
                  {{ getApplicantName(income.applicantId) }} -
                  {{ income.amount | currency : "GBP" : undefined }}
                </div>
              </div>
            </ng-template>
          </hf-card-with-add-button>
        </div>

        <div
          [hfFormField]="control('incomeCalculationExplanation')"
          class="ev-form-field"
        >
          <label>Explain income calculation</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'incomeCalculationExplanation'"
            fieldName="incomeCalculationExplanation"
          ></hf-textarea>
        </div>

        <div
          [hfFormField]="control('agreedMonthlyBudget')"
          class="ev-form-field"
        >
          <label>Agreed monthly budget for new mortgage</label>
          <hfc-text-input
            prefix="£"
            [disableDecimal]="true"
            currency="true"
            formControlName="agreedMonthlyBudget"
          ></hfc-text-input>
        </div>

        <div [hfFormField]="control('giftedDeposit')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox
            label="Gifted Deposit"
            formControlName="giftedDeposit"
          ></hf-checkbox>
        </div>

        <div [hfFormField]="control('adverseCredit')" class="ev-form-field">
          <!-- <label><wbr></label> -->
          <hf-checkbox
            label="Adverse Credit"
            formControlName="adverseCredit"
          ></hf-checkbox>
        </div>
      </div>

      <div class="ev-sidepane-form-column">
        <div
          *ngIf="enabled('depositObservedEvidence')"
          [hfFormField]="control('depositObservedEvidence')"
          class="ev-form-field"
        >
          <label>Evidence of deposit observed</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'depositObservedEvidence'"
            fieldName="depositObservedEvidence"
          ></hf-textarea>
        </div>

        <div
          [hfFormField]="control('knownIncomeChanges')"
          class="ev-form-field"
        >
          <label><wbr /></label>
          <hf-checkbox
            label="Any known income changes?"
            formControlName="knownIncomeChanges"
          ></hf-checkbox>
        </div>

        <div
          *ngIf="enabled('knownIncomeChangesExplanation')"
          [hfFormField]="control('knownIncomeChangesExplanation')"
          class="ev-form-field"
        >
          <label>Explain known income changes</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'knownIncomeChangesExplanation'"
            fieldName="knownIncomeChangesExplanation"
          ></hf-textarea>
        </div>

        <div [hfFormField]="control('surplus')" class="ev-form-field">
          <label><wbr /></label>
          <hf-checkbox
            label="Are there surplus funds not being used?"
            formControlName="surplus"
          ></hf-checkbox>
        </div>
        <div
          *ngIf="enabled('surplusAmount')"
          [hfFormField]="control('surplusAmount')"
          class="ev-form-field"
        >
          <label>Amount of surplus funds</label>
          <hfc-text-input
            prefix="£"
            [disableDecimal]="true"
            currency="true"
            formControlName="surplusAmount"
          ></hfc-text-input>
        </div>
        <div
          *ngIf="enabled('surplusDetails')"
          [hfFormField]="control('surplusDetails')"
          class="ev-form-field"
        >
          <label>Why are surplus funds not being used?</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'surplusDetails'"
            fieldName="surplusDetails"
          ></hf-textarea>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="form.get('reasonForChange').enabled"
    [hfFormField]="control('reasonForChange')"
    class="ev-sidepane-form-section top-border"
  >
    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column-full-width">
        <div class="ev-form-field">
          <label>Reason for Change</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'reasonForChange'"
            fieldName="reasonForChange"
          ></hf-textarea>
        </div>
      </div>
    </div>
  </div>

  <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
</form>
