<form [formGroup]="form" class="ev-sidepane-form">
  <div class="ev-sidepane-form-section">
    <!-- <div class="ev-sidepane-form-heading">General Notes</div> -->
    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('objective')" class="ev-form-field">
          <label>Objectives</label>
          <hf-selector-link
            placeholder="Select Objectives"
            [value]="
              control('otherObjective').value ||
              MORTGAGE_OBJECTIVE_NAME.get(control('objective').value)
            "
            (click)="onSelectObjectivesClicked()"
          >
          </hf-selector-link>
        </div>

        <div [hfFormField]="control('scheme')" class="ev-form-field">
          <label>Scheme</label>
          <hf-selector-link
            placeholder="Select Scheme"
            [value]="APPLICANT_SCHEME_NAMES.get(control('scheme').value)"
            (click)="onSelectSchemeClicked()"
          >
          </hf-selector-link>
        </div>

        <div
          *ngIf="enabled('remortgageReason')"
          [hfFormField]="control('remortgageReason')"
          class="ev-form-field"
        >
          <label>Reason for Remortgage</label>
          <hf-selector-link
            *ngIf="value.otherRemortgageReason"
            placeholder="Select Reason"
            [value]="value.otherRemortgageReason"
            (click)="onSelectRemortgageReasonClicked()"
          >
          </hf-selector-link>

          <ng-container *ngIf="!value.otherRemortgageReason">
            <hf-selector-link
              *ngFor="let reason of value.remortgageReason || [null]"
              placeholder="Select Reason"
              [value]="RemortgageReasonName.get(reason)"
              (click)="onSelectRemortgageReasonClicked()"
            >
            </hf-selector-link>
          </ng-container>
        </div>

        <div
          *ngIf="enabled('increasedBorrowingReason')"
          [hfFormField]="control('increasedBorrowingReason')"
          class="ev-form-field"
        >
          <label>Reason for increasing borrowing</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'increasedBorrowingReason'"
            fieldName="increasedBorrowingReason"
          ></hf-textarea>
        </div>
      </div>

      <div class="ev-sidepane-form-column">
        <div [hfFormField]="control('debtConsolidation')" class="ev-form-field">
          <label><wbr /></label>
          <hf-checkbox
            label="Debt Consolidation"
            formControlName="debtConsolidation"
          ></hf-checkbox>
        </div>

        <div
          *ngIf="enabled('amountUsedToRepay')"
          [hfFormField]="control('amountUsedToRepay')"
          class="ev-form-field"
        >
          <label>Amount of loan used to repay existing debt</label>
          <hfc-text-input
            prefix="£"
            [disableDecimal]="true"
            currency="true"
            formControlName="amountUsedToRepay"
          ></hfc-text-input>
        </div>

        <div
          *ngIf="enabled('debtConsolidationReason')"
          [hfFormField]="control('debtConsolidationReason')"
          class="ev-form-field"
        >
          <label>Reason for debt consolidation</label>
          <hf-selector-link
            *ngIf="value.otherDebtConsolidationReason"
            placeholder="Select Explanation"
            [value]="value.otherDebtConsolidationReason"
            (click)="onSelectDebtConsolidationReasonClicked()"
          >
          </hf-selector-link>

          <ng-container *ngIf="!value.otherDebtConsolidationReason">
            <hf-selector-link
              *ngFor="let reason of value.debtConsolidationReason || [null]"
              placeholder="Select Explanation"
              [value]="MortgageDebtConsolidationReasonName.get(reason)"
              (click)="onSelectDebtConsolidationReasonClicked()"
            >
            </hf-selector-link>
          </ng-container>
        </div>

        <div
          *ngIf="enabled('debtConsolidationNote')"
          [hfFormField]="control('debtConsolidationNote')"
          class="ev-form-field"
        >
          <label>Debt consolidation notes</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'debtConsolidationNote'"
            fieldName="debtConsolidationNote"
          ></hf-textarea>
        </div>
      </div>

      <!-- <div class="ev-sidepane-form-column"> -->
      <!--   <div class="ev-form-field" style="margin-top: 8px;"> -->
      <!--     <hf-checkbox label="The client has existing provisions" formControlName="hasExistingProvisions"></hf-checkbox> -->
      <!--   </div> -->

      <!--   <div *ngIf="isControlEnabled('existingProvisions')" [hfFormField]="control('existingProvisions')" class="ev-form-field"> -->
      <!--     <label>Specify existing provisions</label> -->
      <!--     <hf-textarea [group]="form" [smaller]="true" [inputFormControlName]="'existingProvisions'" fieldName="existingProvisions"></hf-textarea> -->
      <!--   </div> -->
      <!-- </div> -->
    </div>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div
    *ngIf="form.get('reasonForChange').enabled"
    [hfFormField]="control('reasonForChange')"
    class="ev-sidepane-form-section top-border"
  >
    <div class="ev-sidepane-form-content">
      <div class="ev-sidepane-form-column-full-width">
        <div class="ev-form-field">
          <label>Reason for Change</label>
          <hf-textarea
            [group]="form"
            [smaller]="true"
            [inputFormControlName]="'reasonForChange'"
            fieldName="reasonForChange"
          ></hf-textarea>
        </div>
      </div>
    </div>
  </div>
</form>
