<div class="container" [ngClass]="result.decision">
  <i class="material-icons"></i>

  <div>
    <div class="result">
      <img src="/assets/external/experian-logo.svg" alt="Experian logo" />

      <span
        *ngIf="
          result.decision === 'ACCEPT' ||
          result.decision === 'PASS' ||
          result.decision === 'CONTINUE'
        "
        >Identity verified</span
      >
      <span
        *ngIf="result.decision === 'REFER' || result.decision === 'NODECISION'"
        >Unable to verify identity</span
      >
      <span *ngIf="result.decision === 'ERROR' || result.decision === 'STOP'"
        >AML check failed</span
      >
    </div>

    <div
      *ngIf="result.decision === 'ERROR' || result.decision === 'STOP'"
      class="errors"
    >
      {{ result.decisionText }}
    </div>
  </div>
</div>
