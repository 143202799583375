<section class="section">
  <div class="table-page-container">
    <hf-pinned-note-box
      *ngIf="pinnedNote"
      [pinnedNote]="pinnedNote"
      style="margin-bottom: 16px"
    ></hf-pinned-note-box>

    <div class="table-tools-container">
      <hf-custom-text-field
        type="text"
        [searchMode]="true"
        placeholder="Search journal …"
        fieldName="input12"
        icon="search"
        preventValidation="true"
      >
      </hf-custom-text-field>

      <div class="table-tools-container_end">
        <hf-custom-button
          type="button"
          class="generic-gray-btn"
          icon="refresh"
          iconPos="right"
          textPos="left"
          label="Refresh entries"
          (click)="onRefreshJournal()"
        >
        </hf-custom-button>
        <hf-selector-link
          *ngIf="showApplication"
          placeholder="Select Application"
          [value]="application?.lender"
          (click)="onSelectApplicationClicked()"
          class="select-application"
        >
        </hf-selector-link>
      </div>
    </div>

    <hf-journal
      [dataSourceFactory]="journalDataSourceFactory"
      #journalComponentToRefresh
    ></hf-journal>
  </div>
</section>
