import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "hf-star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["./star-rating.component.scss"],
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number = 0;
  @Input() maxStars?: number = 4; // Total number of stars (default is 4)
  stars: Array<boolean | "half"> = [];

  ngOnInit(): void {
    this.generateStars();
  }

  public trackByFn(item: boolean | "half") {
    return item;
  }

  private generateStars(): void {
    this.stars = Array.from({ length: this.maxStars }, (_, i) => {
      if (i < Math.floor(this.rating)) return true;
      if (i < this.rating) return "half"; // Half-filled, if needed
      return false;
    });
  }
}
