<hf-sidepane class="cols-2" [@sidepaneMove2Cols]>
  <header>Send Message</header>
  <body>
    <form [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Send to</label>

              <hf-selector-link
                *ngFor="let applicant of sh.value.recipients || [null]"
                placeholder="Select Applicants"
                [value]="applicant?.fullName"
                (click)="onSelectApplicantsClicked()"
              >
              </hf-selector-link>
            </div>
          </div>

          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Copy to (Optional)</label>
              <hf-selector-link
                placeholder="Select recipients"
                [value]="ccEmailLabel"
                (click)="onSelectCcEmailClicked()"
              >
              </hf-selector-link>
            </div>
          </div>
        </div>

        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">
            <div class="ev-form-field">
              <label>Subject</label>
              <hfc-text-input formControlName="subject"></hfc-text-input>
            </div>
            <div class="ev-form-field">
              <label>Message</label>

              <hfc-textarea-input
                [editor]="true"
                formControlName="body"
                [isZoomContent]="zoomContent?.duration > 0 ? true : false"
              ></hfc-textarea-input>
            </div>
            <div class="ai-banner" *ngIf="zoomMessageState">
              <i class="material-icons">auto_awesome_outline</i>
              <p>
                This meeting summary was AI generated, please review before
                sending.
              </p>
            </div>
            <div class="ev-form-field">
              <!-- <hf-fileuploader controlName="attachments" [group]="form" [fileControl]="form.value['attachments']" [showMaxHint]="true"> -->
              <!-- </hf-fileuploader> -->

              <!-- <hfc-fact-find-upload formControlName="attachments"></hfc-fact-find-upload> -->
              <hf-file-input
                [multiple]="true"
                formControlName="attachments"
              ></hf-file-input>

              <div class="hint">Maximum total size of attachments is 10MB</div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </body>
</hf-sidepane>
