import { AbstractControl, ValidatorFn } from "@angular/forms";

/**
 * Validator for Zoom extension numbers.
 * Ensures the value is 3-4 digits long and contains only numbers.
 */
export function getZoomExtensionValidator(): ValidatorFn {
  const regexp = /^\d{3,4}$/; // Matches numbers with 3-4 digits

  return (control: AbstractControl) => {
    const value: string = control.value;

    // If no value, consider valid (let required validator handle empty case)
    if (!value) {
      return null;
    }

    // Ensure value contains only numbers and is 3-4 digits long
    return regexp.test(value) ? null : { zoomExtensionValidator: true };
  };
}
