<div class="custom-infobox-container">
  <div class="icon-container">
    <img
      *ngIf="icon; else sentimentIconTemplate"
      [src]="icon"
      [alt]="title + ' icon'"
    />
    <ng-template #sentimentIconTemplate>
      <img
        *ngIf="customerSentiment"
        [src]="getCustomerSentimentIcon()"
        [alt]="customerSentiment + ' sentiment icon'"
      />
    </ng-template>
  </div>
  <div class="information-container">
    <ng-container *ngIf="isRatingVisible; else titleTemplate">
      <hf-star-rating [rating]="rating" [maxStars]="4"></hf-star-rating>
    </ng-container>
    <ng-template #titleTemplate>
      <div class="information-container_title">
        {{
          customerSentiment
            ? returnFormattedCustomerSentimentTitle(title)
            : title
        }}
      </div>
    </ng-template>

    <div class="information-container_text">{{ text }}</div>
  </div>
</div>
