import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "hf-custom-infobox",
  templateUrl: "./custom-infobox.component.html",
  styleUrls: ["./custom-infobox.component.scss"],
})
export class CustomInfoboxComponent {
  @Input() icon?: string; // Icon URL
  @Input() title?: string;
  @Input() text?: string;
  @Input() rating?: number; // Star rating, or salesPotential value
  @Input() isRatingVisible: boolean = false; // If false, shows title instead

  // Optional fallback logic for customer sentiment icons
  @Input() customerSentiment?: string;
  @Input() sentimentIcons?: { [key: string]: string } = {
    POSITIVE: "assets/icons/PositiveCustomerSentiment.svg",
    NEGATIVE: "assets/icons/NegativeCustomerSentiment.svg",
    NEUTRAL: "assets/icons/NeutralCustomerSentiment.svg",
    INCONCLUSIVE: "assets/icons/InconclusiveCustomerSentiment.svg",
  };

  constructor() {}

  // Returns the appropriate sentiment icon
  getCustomerSentimentIcon(): string | undefined {
    return this.customerSentiment
      ? this.sentimentIcons?.[this.customerSentiment] ||
          "assets/icons/NeutralCustomerSentiment.svg"
      : undefined;
  }

  returnFormattedCustomerSentimentTitle(title: string) {
    if (title === "NEGATIVE") return "Unhappy";
    return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
  }
}
