<p>Rate the accuracy of this response</p>

<div>
  <hf-custom-button
    type="button"
    class="button-plain-icon"
    [icon]="
      callAccuracyRating !== SummaryRatingEnum.ThumbsUp
        ? 'thumb_up_off_alt'
        : 'thumb_up'
    "
    iconPos="center"
    (click)="callAccuracyRatingToggle(SummaryRatingEnum.ThumbsUp)"
  >
  </hf-custom-button>

  <hf-custom-button
    type="button"
    class="button-plain-icon"
    [icon]="
      callAccuracyRating !== SummaryRatingEnum.ThumbsDown
        ? 'thumb_down_off_alt'
        : 'thumb_down'
    "
    iconPos="center"
    (click)="callAccuracyRatingToggle(SummaryRatingEnum.ThumbsDown)"
  >
  </hf-custom-button>
</div>

<p
  class="feedback-text"
  *ngIf="showFeedbackMessage"
  [class.fade-out]="fadeOutFeedbackMessage"
>
  Thanks for your feedback
</p>
