import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FullCallDetailsDTO,
  SummaryRatingEnum,
} from "../../models/full-call-details.dto";
import { JournalEntry } from "../../models/journal-details";
import { JOURNAL_ACTIVITY_WHITE } from "../../utils/variables.data";
import { convertSecondsToTime } from "../../utils/functions/timeConversion.helper";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DialogService,
  ToastService,
} from "../../../../../projects/client/src/app/shared/services";
import { ZoomCallSummaryService } from "../../services/zoom-call-summary.service";
import { catchError, finalize } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { TableSettingsService } from "../table-utils/table-settings.service";
import { clampExpandAnimation } from "../../../../../projects/client/src/app/shared/animations/expand.animation";
import { extractKeyValue } from "../../utils/functions/extract-callDataId.function";

@Component({
  selector: "hf-zoom-call-summary-journal-item",
  templateUrl: "./zoom-call-summary-journal-item.component.html",
  styleUrls: ["./zoom-call-summary-journal-item.component.scss"],
  animations: [clampExpandAnimation],
})
export class ZoomCallSummaryJournalItemComponent implements OnInit {
  @Input()
  public item!: JournalEntry;
  @Input() public zoomMeetingTitle?: string;

  @ViewChild("bodyContainer")
  public bodyContainerEl: ElementRef;

  public callAccuracy: SummaryRatingEnum;
  public isSeeLessVisible = true;
  public hasClampClass = true;
  public anim = "clamp";
  public callDataId?: number;
  public zoomCallSummary: FullCallDetailsDTO;

  @HostBinding("class.isWhite")
  public get isWhite() {
    return JOURNAL_ACTIVITY_WHITE.has(this.item?.activity);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zoomCallSummaryService: ZoomCallSummaryService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private tableSettingsService: TableSettingsService
  ) {}
  ngOnInit(): void {
    this.fetchCallDataId(this.item);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.zoomCallSummary && this.zoomCallSummary?.summaryRating) {
      this.onCallAccuracyRatingEmitted(this.zoomCallSummary.summaryRating);
    }
  }

  private fetchCallDataId(journalContent: JournalEntry): void {
    this.callDataId = extractKeyValue(journalContent, "callDataId");
    if (this.callDataId) {
      let callIdNumber = Number(this.callDataId);
      this.getZoomCallSummaryJournalFactory(callIdNumber);
    }
  }

  public getHostName(): string | null {
    return this.getAttendeeByType("ORGANIZER")?.name || null;
  }

  public getDuration(): string {
    return convertSecondsToTime(this.zoomCallSummary?.duration);
  }

  private getAttendeeByType(
    type: string
  ): { name: string; phone: string } | undefined {
    return this.zoomCallSummary?.attendees?.find(
      (attendee) => attendee.type === type
    );
  }

  public async onDeleteClicked(): Promise<void> {
    const confirmed = await this.dialogService.confirm({
      title: "Delete AI Summary",
      message:
        "Are you sure you'd like to delete this summary? This action cannot be undone.",
      acceptLabel: "Delete",
    });

    if (confirmed) {
      this.deleteZoomCallSummary();
    }
  }

  public onDraftEmailForClients() {
    this.zoomCallSummaryService.setZoomAISummary(
      this.zoomMeetingTitle,
      this.zoomCallSummary
    );
    this.router.navigate(["sendMessage"], {
      relativeTo: this.route,
      state: { isZoom: true },
    });
  }

  public onCallAccuracyRatingEmitted(rating: SummaryRatingEnum) {
    this.callAccuracy = rating;
  }

  public onSeeZoomLengthToggle(seeLess?: boolean) {
    if (!this.isSeeLessVisible && !seeLess) {
      return;
    }
    this.isSeeLessVisible = !this.isSeeLessVisible;
    this.hasClampClass = !this.hasClampClass;
    this.onClampDone();
  }

  public onClampDone() {
    if (this.isSeeLessVisible) {
      this.hasClampClass = true;
      this.anim = "clamp";
    } else this.anim = "open";
  }

  private deleteZoomCallSummary(): void {
    this.zoomCallSummaryService
      .deleteZoomCallSummary(this.callDataId, Number(this.item.id))
      .pipe(
        catchError((error) => this.handleDeleteError(error)),
        finalize(() => this.tableSettingsService.refresh())
      )
      .subscribe();
  }

  private handleDeleteError(error: any): Observable<never> {
    const message =
      error.status === 401 || error.status === 403
        ? "User is unauthorized to delete this journal entry."
        : error.status === 404
        ? "Zoom AI summary not found."
        : "An error occurred while deleting the journal entry.";
    this.toastService.add(message);
    return throwError(error);
  }

  private getZoomCallSummaryJournalFactory(callDataId?: number) {
    return this.zoomCallSummaryService
      .loadZoomCallSummary(callDataId)
      .pipe(
        catchError((err) => {
          console.log("Error loading zoom summary: ", err);
          return of(undefined);
        })
      )
      .subscribe((zoomCallSummaryData: FullCallDetailsDTO) => {
        this.zoomCallSummary = zoomCallSummaryData;
      });
  }
}
