import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReviewSectionState } from "../../../../../../shared/components/review-section/review-section.component";
import {
  GENDER_NAME,
  MARTITAL_STATUS_NAME,
  NAME_CHANGE_REASON_NAMES,
  PERSONAL_TITLE_NAME,
  VISA_STATUS_NAMES,
} from "../../../../../enums.module";
import {
  ApplicantData,
  SmartSearchResult,
} from "../../../../../services/fact-find-api.service";
import { getApplicantName } from "../get-applicant-name.function";

@Component({
  selector: "hfc-personal-details-review-section",
  templateUrl: "./personal-details-review-section.component.html",
  styleUrls: ["./personal-details-review-section.component.scss"],
})
export class PersonalDetailsReviewSectionComponent {
  @Input()
  public applicantData: ApplicantData;

  @Input()
  public config: ReviewSectionState;

  @Output()
  public buttonClick = new EventEmitter<ReviewSectionState>();

  @Input()
  public showSmartSearch = false;

  public PERSONAL_TITLE_NAME = PERSONAL_TITLE_NAME;
  public GENDER_NAME = GENDER_NAME;
  public MARTITAL_STATUS_NAME = MARTITAL_STATUS_NAME;
  public VISA_STATUS_NAMES = VISA_STATUS_NAMES;
  public NAME_CHANGE_REASON_NAMES = NAME_CHANGE_REASON_NAMES;

  public getApplicantName = getApplicantName;

  public getSmartSearchResultLabel(result: SmartSearchResult) {
    if (!result.useSmartsearch) {
      return "Applicant opted out of SmartSearch";
    }

    switch (result.amlResult) {
      case "PASS":
      case "CONTINUE":
      case "ACCEPT":
        return "Identity verified by SmartSearch";

      case "STOP":
        return "SmartSearch failed, do not approve";

      case "NODECISION":
        return "SmartSearch failed, internal error";

      case "REFER":
        return "Identity referred by SmartSearch, investigate further";

      case "ERROR":
        return "SmartSearch failed, unable to verify identity";

      default:
        return "Awaiting identity result from SmartSearch";
    }
  }

  public getSmartSearchResultIcon(result: SmartSearchResult) {
    if (!result.useSmartsearch) {
      return "block";
    }

    switch (result.amlResult) {
      case "PASS":
      case "CONTINUE":
      case "ACCEPT":
        return "check_circle";

      case "REFER":
      case "ERROR":
      case "NODECISION":
      case "STOP":
        return "error";

      default:
        return "timelapse";
    }
  }

  public onSmartSearchClicked(result: SmartSearchResult) {
    result.link && window.open(result.link, "_blank");
  }
}
