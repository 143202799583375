import {
  Component,
  Input,
  HostBinding,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  JournalEntry,
  JournalEntryConnectedDocument,
} from "../../models/journal-details";
import { JOURNAL_ACTIVITY_WHITE } from "../../utils/variables.data";
import { DocumentThumbnail } from "../../../../../projects/client/src/app/shared/components/document-thumbnail/document-thumbnail.component";
import { JournalApiService } from "../../services/journal-api.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { expandAnimation } from "../../../../../projects/client/src/app/shared/animations/expand.animation";
import { MessageJournalItemMessageComponent } from "./message-journal-item-message/message-journal-item-message.component";

const mockPhoto = {
  id: 2953,
  createdDate: "2021-10-27T10:45:36.318Z",
  filename: "Profile_photo_Wed_27-10-2021_10-45-18_814_75.jpg",
  folder: "user/1",
  thumbnail: "Profile_photo_Wed_27-10-2021_10-45-18_814_75_thumbnail@xN.jpg",
  originalFilename: "90s-lede-1300x731.jpg",
};

@Component({
  selector: "hf-message-journal-item",
  templateUrl: "./message-journal-item.component.html",
  styleUrls: ["./message-journal-item.component.scss"],
  animations: [expandAnimation],
})
export class MessageJournalItemComponent implements OnDestroy {
  @Input()
  public item: JournalEntry;

  @HostBinding("class.isWhite")
  public get isWhite() {
    return JOURNAL_ACTIVITY_WHITE.has(this.item?.activity);
  }

  @ViewChild("message")
  public messageComponent: MessageJournalItemMessageComponent;

  public replies: any[];
  public photo = mockPhoto;
  public isLoading = false;
  private initialized = false;
  private destroy$ = new Subject();

  constructor(private journalApiService: JournalApiService) {}

  public onSeeThreadClicked() {
    !this.initialized && this.initializeThread();
  }

  public onSeeLess() {
    this.replies = null;
    this.initialized = false;
    this.messageComponent?.onSeeLessClicked();
  }

  private initializeThread() {
    this.initialized = true;
    this.isLoading = true;

    this.journalApiService
      .loadEmailThread(this.item.email.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((replies) => {
        this.replies = replies.items;
        this.isLoading = false;
      });
  }

  public getDocumentThumbnail(document: JournalEntryConnectedDocument) {
    const thumbnail: DocumentThumbnail = {
      customType:
        document.attachment.originalFilename || document.attachment.filename,
      file: document.attachment,
    };

    return thumbnail;
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }
}
