import { Component, Input } from "@angular/core";
import { CustomerSentimentEnum } from "../../models/full-call-details.dto";

@Component({
  selector: "hf-custom-infobox-container",
  templateUrl: "./custom-infobox-container.component.html",
  styleUrls: ["./custom-infobox-container.component.scss"],
})
export class CustomInfoboxContainerComponent {
  @Input() customerSentiment: CustomerSentimentEnum;
  @Input() salesPotentialRating: number = 0;

  constructor() {}
}
