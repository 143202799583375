<div class="star-rating">
  <ng-container *ngFor="let star of stars; trackBy: trackByFn">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="star"
      [ngClass]="{
        filled: star === true,
        half: star === 'half'
      }"
    >
      <path
        d="M12 2.5l3.09 6.26L22 9.27l-5 4.87L18.18 21 12 17.27 5.82 21 7 14.14l-5-4.87 6.91-1.01L12 2.5z"
      ></path>
      <defs>
        <linearGradient id="half-fill" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="50%" style="stop-color: #c59c35; stop-opacity: 1" />
          <stop offset="50%" style="stop-color: transparent; stop-opacity: 1" />
        </linearGradient>
      </defs>
    </svg>
  </ng-container>
</div>
