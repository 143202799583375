<div (click)="onBack()" class="overlay" [@fadeAnimation]></div>

<div class="sidepane-content cols-2">
  <div class="flex-space-between-layout template-header">
    <h1>{{ isEditMode ? "Edit Application" : "Add Application" }}</h1>
    <hf-custom-button
      type="button"
      class="button-plain-icon"
      icon="close"
      iconPos="center"
      (onClick)="onBack()"
    >
    </hf-custom-button>
  </div>

  <div class="template-main">
    <hf-custom-spinner
      *ngIf="isLoading"
      sidepaneLoading="true"
    ></hf-custom-spinner>

    <form *ngIf="!isLoading" [formGroup]="form" class="ev-sidepane-form">
      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Provider</label>
              <hf-selector-link
                placeholder="Select Provider"
                [value]="form.value?.provider?.name"
                (click)="onSelectProviderClicked()"
              >
              </hf-selector-link>
            </div>
          </div>
          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Product Ref</label>
              <hfc-text-input formControlName="productName"></hfc-text-input>
            </div>
          </div>
        </div>
      </div>

      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column-full-width">
            <div class="ev-form-field">
              <label>Benefits</label>
              <hfc-info-box-container>
                <hfc-info-box
                  *ngFor="let benefit of value.benefits"
                  [title]="BenefitTypeName.get(benefit.type)"
                  [details]="getBenefitDetails(benefit)"
                  (click)="onAddBenefitClicked(benefit)"
                ></hfc-info-box>

                <div class="add-button-separator"></div>
                <hfc-add-button (click)="onAddBenefitClicked()"
                  >Add Benefit</hfc-add-button
                >
              </hfc-info-box-container>
            </div>
          </div>
        </div>
      </div>

      <div class="ev-sidepane-form-section">
        <div class="ev-sidepane-form-heading">Quote</div>
        <div class="ev-sidepane-form-content">
          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Monthly Premium</label>
              <hfc-text-input
                prefix="£"
                currency="true"
                formControlName="monthlyPremium"
              ></hfc-text-input>
            </div>

            <div class="ev-form-field">
              <label>Does the quote have an expiry date?</label>
              <hfc-yes-no-checkbox
                formControlName="hasExpiryDate"
              ></hfc-yes-no-checkbox>
            </div>

            <div class="ev-form-field" *ngIf="form.get('expiryDate').enabled">
              <label>Quote Expiry</label>
              <hf-date-input
                formControlName="expiryDate"
                propertyName="expiryDate"
              ></hf-date-input>
            </div>

            <div class="ev-form-field">
              <label>Do you know the commission amount?</label>
              <hfc-yes-no-checkbox
                formControlName="hasCommission"
              ></hfc-yes-no-checkbox>
            </div>

            <div
              class="ev-form-field"
              *ngIf="form.get('commissionAmount').enabled"
            >
              <label>Commission</label>
              <hfc-text-input
                prefix="£"
                [disableDecimal]="true"
                currency="true"
                formControlName="commissionAmount"
              ></hfc-text-input>
            </div>
          </div>

          <div class="ev-sidepane-form-column">
            <div class="ev-form-field">
              <label>Illustration Document</label>
              <hf-file-input formControlName="illustration"></hf-file-input>
            </div>

            <div class="ev-form-field">
              <label>Comparison Report<br />(without commission)</label>
              <hf-file-input formControlName="comparisonReport"></hf-file-input>
            </div>

            <div class="ev-form-field">
              <label>Other Documentation</label>
              <hf-file-input
                formControlName="otherDocuments"
                [multiple]="true"
              ></hf-file-input>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
  </div>

  <div class="template-footer">
    <hfc-button
      type="primary"
      [disabled]="form.invalid"
      [isLoading]="isSubmitting"
      (click)="onSubmit()"
      >{{ isEditMode ? "Save" : "Add Application" }}</hfc-button
    >
    <hfc-button type="secondary" (click)="onBack()">Cancel</hfc-button>

    <hf-custom-button
      type="button"
      *ngIf="isEditMode"
      class="button-plain-icon delete-button keep-right"
      icon="delete"
      iconPos="center"
      (onClick)="onRemove()"
    >
    </hf-custom-button>
  </div>
</div>

<div class="sidepane-child-anchor">
  <router-outlet></router-outlet>
</div>
